import { template as template_08d42564bdf0400b8973be4f3db28646 } from "@ember/template-compiler";
const FKText = template_08d42564bdf0400b8973be4f3db28646(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
